/** @jsx jsx */
import { jsx, Container, Heading } from "theme-ui"
import { graphql } from "gatsby"
import { Layout, SEO, FAQList } from "../components"
import GetCashOfferForm from "../forms/get-cash-offer"

const FAQ = ({ data }) => {
  const { allContentfulFaQs } = data

  return (
    <Layout>
      <SEO title="FAQs" />
      <Container sx={{ px: 3, py: [4, 5, 100] }}>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["1fr", null, "2fr 1fr"],
            gridGap: [4, 5],
          }}
        >
          <div>
            <Heading
              as="h1"
              sx={{
                fontSize: 5,
                mb: 4,
                textAlign: "center",
              }}
            >
              Our FAQs
            </Heading>
            <FAQList list={allContentfulFaQs.edges} />
          </div>
          <div>
            <GetCashOfferForm />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulFaQs {
      edges {
        node {
          id
          title
          description {
            raw
          }
        }
      }
    }
  }
`

export default FAQ
